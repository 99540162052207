import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    ANDROID: "Android",
    IOS: "iOS",
    BOTH: "Both",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
        title: "FingeRate",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "Engage in swift and easy surveys, voice your opinions, and earn attractive rewards! This app values your perspective, offering a seamless way for you to contribute your thoughts and watch your rewards accumulate. Make your opinions count and see your earnings flourish with each survey you complete!",
          client: "Genesis Lab",
          technologies: "Flutter, Dart, Firebase, Metaverse, Unity",
          industry: "Information Technology",
          date: "Jan 2022 - May 2023",
          url: {
            name: "FingeRate",
            link: "https://play.google.com/store/apps/details?id=com.frs.fingerate",
          },
//           sliderImages: [
//             "images/projects/detail_pictures/fingerate-1.webp",
//             "images/projects/detail_pictures/fingerate1.webp",
//           ],
        },

        thumbImage: "images/projects/fingerate.webp",
        categories: [filters.BOTH],
      },
    {
      title: "Easel",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Using Easel with the Pylons Wallet, you can seamlessly upload and sell your own images, videos, audio, 3D objects, and more on-chain with no hassle directly from your device! Share them to your favorite social network for your friends or fans to buy.",
        client: "Genesis Lab",
        technologies: "Flutter, Dart, Firebase, Blockchain",
        industry: "Information Technology",
        date: "May 2022 - Jan 2023",
        url: {
          name: "Easel",
          link: "https://play.google.com/store/apps/details?id=tech.pylons.easel&hl=en",
        },
//         sliderImages: [
//           "images/projects/project-2.jpg",
//           "images/projects/project-5.jpg",
//         ],
      },

      thumbImage: "images/projects/easel.webp",
      categories: [filters.BOTH],
    },
    {
      title: "Pylons",
      type: types.DOCUMENT,
   document: {
        projectInfo:"Brand new secure, community-driven experience. Pylons is your gateway to the world of digital property, from art to games and more!. Store your digital property directly on your device!",
        client: "Genesis Lab",
        technologies: "Flutter, Dart, Firebase, Blockchain",
        industry: "Information Technology",
        date: "May 2022 - Jan 2023",
        url: {
          name: "Pylons",
          link: "https://play.google.com/store/apps/details?id=tech.pylons.wallet&hl=el",
        },
//         sliderImages: [
//           "images/projects/project-2.jpg",
//           "images/projects/project-5.jpg",
//         ],
      },
      thumbImage: "images/projects/pylons.webp",
      categories: [filters.BOTH],
    },
       {
          title: "Qazi Nabeel",
          type: types.DOCUMENT,
       document: {
            projectInfo:"Discover the world of stock market investing with our cutting-edge application, designed to empower users with comprehensive stock market courses at their fingertips. This user-friendly app is an educational hub for beginners and seasoned traders alike, providing a wealth of knowledge in an easily digestible format. Whether you're looking to understand the basics of stock trading or delve into advanced investment strategies, our app covers it all",
            client: "Qazi Nabil",
            technologies: "Flutter, Dart, Firebase, Rest APIs",
            industry: "Information Technology",
            date: "May 2022 - Aug 2022",
            url: {
              name: "Qazi Nabeel",
              link: "https://play.google.com/store/apps/details?id=com.kashif.courses",
            },
//             sliderImages: [
//               "images/projects/project-2.jpg",
//               "images/projects/project-5.jpg",
//             ],
          },
          thumbImage: "images/projects/qazi.webp",
          categories: [filters.ANDROID],
        },
         {
                  title: "Fe ElsekKa",
                  type: types.DOCUMENT,
               document: {
                    projectInfo:"Discover a world of flavors with our streamlined food ordering app, designed for the ultimate convenience. Explore a wide range of restaurants and cuisines, order with ease, and enjoy customizable options to cater to every dietary need. Fast, secure, and user-friendly, our app brings your favorite meals directly to your doorstep. Whether you're craving comfort food or exotic dishes, our app is your one-stop solution for hassle-free dining anytime, anywhere. Download now for a delightful culinary experience!",
                    client: "UtoR Solutions",
                    technologies: "Flutter, Dart, Firebase, Rest APIs, SignalR",
                    industry: "Information Technology",
                    date: "Sep 2021 - May 2022",
                    url: {
                      name: "Fe ElsekKa",
                      link: "https://apps.apple.com/us/app/fe-elsekka/id1594551936",
                    },
//                     sliderImages: [
//                       "images/projects/project-2.jpg",
//                       "images/projects/project-5.jpg",
//                     ],
                  },
                  thumbImage: "images/projects/feelska.webp",
                  categories: [filters.BOTH],
                },
                 {
                                  title: "Sister United",
                                  type: types.DOCUMENT,
                               document: {
                                    projectInfo:"A fun digital space where young women can have easy access to resources, info on girls topics and support right at their fingertips to help build positive wellbeing and healthy lifestyles. Come learn about ‘Girl Topics’ and issues that are sometimes hard to talk about, be able to access help and support and also engage inspiring and creative online activities with just one click. You’ll receive daily inspiration from other females, information and be part of a wider sisterhood, build positive wellbeing and you can come to this safe digital space to be… INSPIRED, EDUCATED & CONNECTED.",
                                    client: "UtoR Solutions",
                                    technologies: "Flutter, Dart, Firebase, Rest APIs",
                                    industry: "Information Technology",
                                    date: "Feb 2021 - May 2022",
                                    url: {
                                      name: "Sister United",
                                      link: "https://apps.apple.com/pk/app/sisters-united/id1629297356",
                                    },
//                                     sliderImages: [
//                                       "images/projects/project-2.jpg",
//                                       "images/projects/project-5.jpg",
//                                     ],
                                  },
                                  thumbImage: "images/projects/united.jpeg",
                                  categories: [filters.IOS],
                                },
                                 {
                                       title: "Frich",
                                       type: types.DOCUMENT,
                                       document: {
                                          projectInfo:"We are constantly under the pressure to keep up with our friends and, when we can't afford something, it's awkward to say “hey, it's too expensive for me”. Of course, you want to join for the weekend brunch or Thursday night drinks! No one likes to turn down plans because you are supposed to ‘stick to a budget'. That's why we turned personal finances into social finances. Set spending goals together with friends and keep each other accountable through challenges. Challenge each other to take fewer Ubers, have less drinks outside or stop eating out every other night. See each other's progress and achieve the goal together.",
                                          technologies: "Flutter, Dart, Firebase",
                                          industry: "Information Technology",
                                          date: "Nov 2021 - May 2022",
                                          url: {
                                              name: "Frich",
                                              link: "https://apps.apple.com/us/app/frich/id1573562913",
                                           },
//                                           sliderImages: [
//                                               "images/projects/project-2.jpg",
//                                               "images/projects/project-5.jpg",
//                                            ],
                                       },
                                       thumbImage: "images/projects/frich.webp",
                                       categories: [filters.IOS],
                                    },
                                {
                                                                  title: "Umile",
                                                                  type: types.DOCUMENT,
                                                               document: {
                                                                    projectInfo:"Umile is an online market place for providing all types of services like (Salon, Car Wash, Home Cleaning, Plumbing, and Gardening). Where service providers can easily bid on jobs created by service consumer. Access thousands of technicians for your home and office tasks. From Car services to your home cleaning, hire a skilled Umiler immediately.Umile is an online market place for providing all types of services like (Salon, Car Wash, Home Cleaning, Plumbing, and Gardening). Where service providers can easily bid on jobs created by service consumer. Access thousands of technicians for your home and office tasks. From Car services to your home cleaning, hire a skilled Umiler immediately.",
                                                                    client: "UtoR Solutions",
                                                                    technologies: "Flutter, Dart, Firebase, Rest APIs",
                                                                    industry: "Information Technology",
                                                                    date: "Aug 2021 - May 2022",
                                                                    url: {
                                                                      name: "Umile",
                                                                      link: "https://play.google.com/store/apps/details?id=com.app.umile&hl=sv",
                                                                    },
//                                                                     sliderImages: [
//                                                                       "images/projects/project-2.jpg",
//                                                                       "images/projects/project-5.jpg",
//                                                                     ],
                                                                  },
                                                                  thumbImage: "images/projects/umile.webp",
                                                                  categories: [filters.BOTH],
                                                                },
                                                                {
                                                                                                                                  title: "BM Music Player - MP3 Player",
                                                                                                                                  type: types.DOCUMENT,
                                                                                                                               document: {
                                                                                                                                    projectInfo:"Best music player for android is a simple music player app with all the features better than any other music player available for your android devices. Listen to your favourite music with a stylish, powerful, and fast music player.",
                                                                                                                                    client: "",
                                                                                                                                    technologies: "Flutter, Dart",
                                                                                                                                    industry: "Information Technology",
                                                                                                                                    date: "May 2021 - Oct 2021",
                                                                                                                                    url: {
                                                                                                                                      name: "BM Music Player - MP3 Player",
                                                                                                                                      link: "https://play.google.com/store/apps/details?id=com.bestmusicplayer.mp3player.offline.as&hl=fi&gl=US",
                                                                                                                                    },
//                                                                                                                                     sliderImages: [
//                                                                                                                                       "images/projects/project-2.jpg",
//                                                                                                                                       "images/projects/project-5.jpg",
//                                                                                                                                     ],
                                                                                                                                  },
                                                                                                                                  thumbImage: "images/projects/mp3.webp",
                                                                                                                                  categories: [filters.ANDROID],
                                                                                                                                },
 {
    title: "Weather Widgets - Daily Updates",
    type: types.DOCUMENT,
    document: {
       projectInfo:"Weather widgets is an excellent and easy weather app with fun themes. Our free app serves as a weather channel that provides daily updates on the local and global weather conditions. Live weather wherever and whenever you need, stay up to date with this latest weather widgets app",
       technologies: "Flutter, Dart, Firebase",
       industry: "Information Technology",
       date: "May 2021 - Oct 2021",
       url: {
           name: "Weather Widgets - Daily Updates",
           link: "https://play.google.com/store/apps/details?id=com.appseen.live.weather.widgets.daily.weather.update&hl=en_US&gl=US",
        },
//        sliderImages: [
//            "images/projects/project-2.jpg",
//            "images/projects/project-5.jpg",
//         ],
    },
    thumbImage: "images/projects/weather.webp",
    categories: [filters.ANDROID],
 },
 {
     title: "Magnifying Scanner",
     type: types.DOCUMENT,
     document: {
        projectInfo:"Magnifying Scanner is to provide the user with high quality scanned documents along with its handy features to crop, rotate and scale the documents according to his needs. You can easily scan the documents, adjust it conveniently or use the default aspect ratio. And then, saving and sharing is just a click away",
        technologies: "Flutter, Dart",
        industry: "Information Technology",
        date: "Aug 2021 - May 2021",
        url: {
            name: "Magnifying Scanner",
            link: "https://play.google.com/store/apps/details?id=com.devsiom.magnifier&hl=en_US&gl=US",
         },
//         sliderImages: [
//             "images/projects/project-2.jpg",
//             "images/projects/project-5.jpg",
//          ],
     },
     thumbImage: "images/projects/magnifying.webp",
     categories: [filters.ANDROID],
  },
   {
       title: "Deals Finder",
       type: types.DOCUMENT,
       document: {
          projectInfo:"Save your time & money. Find best promo codes and deals right now in this coupon app for free. Deals Finders share coupon deals, free stuff and promo coupons with you so you don’t have to hunt them down yourself.",
          technologies: "Flutter, Dart",
          industry: "Information Technology",
          date: "Jan 2021 - Mar 2021",
          url: {
              name: "Deals Finder",
              link: "https://play.google.com/store/apps/details?id=com.dealsfinders.app&hl=en_US&gl=US",
           },
//           sliderImages: [
//               "images/projects/project-2.jpg",
//               "images/projects/project-5.jpg",
//            ],
       },
       thumbImage: "images/projects/dealsfinder.webp",
       categories: [filters.ANDROID],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
