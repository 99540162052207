import React from "react";
import resumeFile from "../documents/AhmadHassan_2023.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2016 - 2020",
      title: "Bachelors in Software Engineering",
      place: "Riphah International University",
      desc: "I hold a Bachelor of Science degree in Software Engineering from Riphah International University, and it has been an instrumental part of my journey in the world of technology. During my time at this esteemed institution, I delved deep into the world of software engineering, gaining a comprehensive understanding of programming languages, algorithms, database management, and software development methodologies. The experienced and dedicated faculty at Riphah International University guided me through a rigorous curriculum, fostering my analytical and problem-solving skills. The emphasis on practical learning allowed me to apply theoretical knowledge to real-world projects, honing my abilities to create innovative software solutions. The university's state-of-the-art facilities and a diverse learning community enriched my educational experience, providing a solid foundation for my career as a software engineer. My education here has not only equipped me with the technical skills necessary for success but has also instilled in me a commitment to excellence and a passion for innovation in software engineering.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "May 2023 - Current",
      title: "Mobile App Developer",
      place: "Vyro",
      desc: "As a Lead Flutter Developer at Vyro, I spearheaded the development of AI-powered applications, showcasing my expertise in Flutter. Additionally, I ventured into iOS native Swift development to meet project demands. My role at Vyro has been marked by a commitment to innovation and a dynamic approach to software engineering, contributing to cutting-edge solutions in both Flutter and iOS Swift.",
    },
    {
      yearRange: "May 2022 - May 2023",
      title: "Senior Flutter Developer",
      place: "Genesis Lab",
      desc: "During my time at GenesisLab, I engaged in diverse projects that enriched my skillset. I collaborated using GitHub and Bitbucket for code repositories, emphasizing version control. I adopted test-driven development and clean architecture principles for robust software. My experience included rigorous testing to ensure reliability. I also ventured into blockchain and NFT projects, expanding my knowledge of emerging technologies. GenesisLab was a rewarding experience that fueled my growth as a developer.",
    },
    {
      yearRange: "Aug 2021 - `May 2022`",
      title: "Flutter Developer",
      place: "UtoR Solutions",
      desc: "As a software engineer at UtoR Solutions, I worked on diverse projects involving Payment Gateways, SignalR, Google Maps API, Push notifications, Sila, Plaid, and REST APIs. My focus was on creating robust payment solutions, optimizing real-time communication with SignalR, and integrating location-based services using Google Maps API. I also implemented push notifications, facilitated financial data exchange with Sila and Plaid, and extended our platform's capabilities through REST APIs. This experience significantly enhanced my skills in these technologies, making it a pivotal point in my career."
      // desc: "During my tenure as a software engineer at UtoR Solutions, I actively engaged in a wide array of projects, harnessing technologies such as Payment Gateways, SignalR, Google Maps API, Push notifications, Sila, Plaid, and REST APIs. My primary role revolved around crafting robust payment solutions, collaborating with Payment Gateways to facilitate online and mobile transactions. Leveraging SignalR, I ensured seamless real-time communication between server and client applications, enhancing user experiences. My utilization of the Google Maps API integrated location-based features, from providing directions to displaying nearby businesses. Implementing push notifications kept users informed of critical updates, while seamless integration with Sila and Plaid empowered efficient financial data exchange and management. My proficiency in REST APIs further extended our platform's reach, enabling external applications to interact with our services. My tenure at UtoR Solutions served as a crucible for developing a solid foundation in these technologies, fostering invaluable skill growth in the process.",
    },
    {
      yearRange: "Sep 2020 - Aug 2021",
      title: "Flutter Developer",
      place: "Tech Flickers",
      desc: "In 2020, I joined Tech Flickers as a Flutter Developer, dedicating a year to advancing my mobile app development skills. My time there involved active contributions to various projects, collaborating with experts to deliver top-notch solutions. This experience solidified my proficiency in Flutter and exposed me to diverse Flutter projects, enriching my career journey and enhancing my skills."
      // desc: "In 2020, I embarked on a new chapter in my career as a Flutter Developer at Tech Flickers, where I dedicated a year to honing my expertise in mobile app development. Joining this dynamic team allowed me to further expand my skill set and deepen my knowledge of the Flutter framework. During my time at Tech Flickers, I actively contributed to the development of various mobile applications, collaborating with talented professionals to deliver high-quality solutions. This experience not only solidified my position as a proficient Flutter Developer but also provided invaluable exposure to diverse projects within the Flutter ecosystem. Working at Tech Flickers was a pivotal phase in my career journey, allowing me to refine my skills, gain real-world experience, and contribute to innovative mobile app development projects.",
    },
    {
      yearRange: "June 2018 - Sep 2020",
      title: "Jr Flutter Developer",
      place: "CloudTek",
      desc: "From 2018 to 2020, I began my journey in mobile app development at CloudTek as a remote part-time intern specializing in Flutter. I immersed myself in mastering Flutter's UI development, state management, and cross-platform deployment. Through contributions to critical projects, I quickly advanced to Junior Flutter Developer, all while working remotely. This promotion recognized my effectiveness in real-world problem-solving. My collaboration with senior team members at CloudTek honed my skills and led to the successful development of my Final Year Project, a Flutter-based mobile app, while maintaining my remote part-time commitment. This experience enhanced my proficiency in Flutter and remote software development."
      // desc: "From 2018 to 2020, I embarked on a dynamic remote part-time journey within the realm of mobile app development when I joined CloudTek as an intern specializing in Flutter. During this tenure, I diligently immersed myself in the Flutter framework, mastering its widget-based UI development, state management, and cross-platform app deployment. Through active contributions to critical projects undertaken by CloudTek, I swiftly progressed and was promoted to the role of a Junior Flutter Developer, all while working remotely. This promotion acknowledged my ability to apply my skills to real-world challenges effectively. Throughout this entire remote part-time experience, I collaborated closely with my senior team members at CloudTek, learning from their expertise and overcoming various challenges. This collaborative remote part-time experience was instrumental in successfully developing my Final Year Project (FYP) - a mobile application built entirely in Flutter. The project served as a testament to my aptitude in leveraging Flutter's capabilities to address pragmatic problems, all while maintaining a remote part-time commitment. This enriching phase not only refined my Flutter proficiency but also fortified my acumen in thriving within a professional software development milieu in a remote part-time capacity.",
    },
  ];

  const skills = [
    {
      name: "Flutter",
      percent: 90,
    },
    {
      name: "Dart",
      percent: 90,
    },
    {
      name: "TDD",
      percent: 80,
    },
    {
      name: "Firebase",
      percent: 80,
    },
    {
      name: "Swift",
      percent: 75,
    },
    {
      name: "Swift UI",
      percent: 75,
    },
    {
      name: "Kotlin",
      percent: 50,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Experience */}
          <div className="col-lg-7 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Education */}
          <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
